<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    append-to-body
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="优惠券标题" prop="title">
        <el-input
          v-model="dataForm.title"
          :disabled="disabled"
          placeholder="优惠券标题"
        />
      </el-form-item>
      <el-form-item label="优惠券编号" prop="couponSn">
        <el-input
          v-model="dataForm.couponSn"
          :disabled="disabled"
          placeholder="优惠券编号"
        />
      </el-form-item>
      <el-form-item label="最低消费金额" prop="minPrice">
        <el-input-number
          v-model="dataForm.minPrice"
          :min="0"
          :precision="2"
          :disabled="disabled"
          placeholder="最低消费金额"
        />
      </el-form-item>
      <el-form-item label="优惠券类型" prop="couponType">
        <el-radio-group v-model="dataForm.couponType" :disabled="disabled">
          <el-radio :label="1"> 代金券 </el-radio>
          <el-radio :label="2"> 折扣 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="dataForm.couponType === 1"
        label="优惠金额"
        prop="subPrice"
      >
        <el-input
          v-model="dataForm.subPrice"
          type="number"
          :disabled="disabled"
          placeholder="优惠金额"
        >
          <template slot="append"> 元 </template>
        </el-input>
      </el-form-item>
      <el-form-item
        v-show="dataForm.couponType === 2"
        label="折扣率"
        prop="discount"
      >
        <el-input-number
          v-model="dataForm.discount"
          :max="9.9"
          :min="0"
          :step="0.01"
          :precision="2"
          :disabled="disabled"
          placeholder="折扣率"
        />
      </el-form-item>
      <el-form-item label="有效期开始时间" prop="beginTime">
        <el-date-picker
          v-model="dataForm.beginTime"
          type="datetime"
          :disabled="disabled"
          :picker-options="datePicker"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="有效期开始时间"
        />
      </el-form-item>
      <el-form-item label="有效期结束时间" prop="endTime">
        <el-date-picker
          v-model="dataForm.endTime"
          type="datetime"
          :disabled="disabled"
          :picker-options="datePicker"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="有效期结束时间"
        />
      </el-form-item>
      <el-form-item label="优惠券数量" prop="totalCount">
        <el-input-number
          v-model="dataForm.totalCount"
          :min="0"
          :disabled="disabled"
          placeholder="优惠券数量"
        />
      </el-form-item>
      <el-form-item label="供应商" prop="orgId">
        <el-select
          v-model="dataForm.orgId"
          :disabled="disabled"
          clearable
          @change="checkOrg"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="org in orgList"
            :key="org.id"
            :label="org.name"
            :value="org.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="使用类型" prop="limitType">
        <el-radio-group
          v-model="dataForm.limitType"
          :disabled="disabled"
          @input="checkOrg"
        >
          <el-radio :label="0"> 全场通用券 </el-radio>
          <el-radio :label="1"> 指定分类 </el-radio>
          <el-radio :label="2"> 指定商品 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="dataForm.limitType === 1"
        label="指定分类"
        prop="categoryIds"
        :rules="
          dataForm.limitType === 1
            ? [{ required: true, message: '指定分类不能为空' }]
            : []
        "
      >
        <el-select
          v-model="dataForm.categoryIds"
          :disabled="disabled"
          clearable
          multiple
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="category in categoryList"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="dataForm.limitType === 2"
        label="指定商品"
        prop="goodsIds"
        :rules="
          dataForm.limitType === 2
            ? [{ required: true, message: '指定商品不能为空' }]
            : []
        "
      >
        <el-cascader
          v-model="dataForm.goodsIds"
          :disabled="disabled"
          :options="options"
          :props="{ expandTrigger: 'hover', multiple: 'true' }"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="是否限制时段" prop="periodType">
        <el-radio-group
          v-model="dataForm.periodType"
          :disabled="disabled"
          @input="checkOrg"
        >
          <el-radio :label="0"> 不限时段 </el-radio>
          <el-radio :label="1"> 限制时段 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="dataForm.periodType === 1"
        label="指定时段"
        prop="periodIds"
        :rules="
          dataForm.periodType === 1
            ? [{ required: true, message: '指定时段不能为空' }]
            : []
        "
      >
        <el-select
          v-model="dataForm.periodIds"
          :disabled="disabled"
          clearable
          multiple
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="period in periodList"
            :key="period.periodId"
            :label="period.periodWithTimeName"
            :value="period.periodId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="使用范围" prop="type">
        <el-radio-group v-model="dataForm.type" :disabled="disabled">
          <el-radio :label="0"> 通用 </el-radio>
          <el-radio :label="1"> 非团餐 </el-radio>
          <el-radio :label="2"> 团餐 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="每人限领数量" prop="limitUser">
        <el-input-number
          v-model="dataForm.limitUser"
          :min="0"
          :disabled="disabled"
          placeholder="每人限领数量"
        />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number
          v-model="dataForm.sort"
          :min="1"
          :disabled="disabled"
          placeholder="排序"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  getOrgList,
  getCategoryList,
  getGoodsAndPackage,
} from '@/utils/options';
import { keys, pick } from 'lodash';

export default {
  data() {
    let validateMoney = (rule, value, callback) => {
      if (
        !/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
          value,
        )
      ) {
        callback(new Error('格式有误'));
      } else {
        callback();
      }
    };
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        orgId: '',
        title: '',
        couponSn: '',
        couponType: 1,
        minPrice: 1,
        subPrice: 0,
        discount: '',
        beginTime: '',
        endTime: '',
        totalCount: 1,
        limitType: 0,
        type: 0,
        periodType: 0,
        limitUser: 1,
        sort: 1,
        goodsIds: [],
        categoryIds: [],
        periodIds: [],
        goodsAndPackage: [],
      },
      dataRule: {
        title: [
          {
            required: true,
            message: '优惠券标题不能为空',
            trigger: 'blur',
          },
        ],
        couponSn: [
          {
            required: true,
            message: '优惠券编号不能为空',
            trigger: 'blur',
          },
        ],
        subPrice: [
          {
            validator: validateMoney,
            trigger: 'blur',
          },
        ],
        discount: [
          {
            validator: validateMoney,
            trigger: 'blur',
          },
        ],
        minPrice: [
          {
            required: true,
            message: '最低消费金额不能为空',
            trigger: 'blur',
          },
        ],
        beginTime: [
          {
            required: true,
            message: '有效期开始时间不能为空',
            trigger: 'blur',
          },
        ],
        endTime: [
          {
            required: true,
            message: '有效期结束时间不能为空',
            trigger: 'blur',
          },
        ],
        beginGetTime: [
          {
            required: true,
            message: '开始领取时间不能为空',
            trigger: 'blur',
          },
        ],
        endGetTime: [
          {
            required: true,
            message: '结束领取时间不能为空',
            trigger: 'blur',
          },
        ],
        totalCount: [
          {
            required: true,
            message: '优惠券发放数量不能为空',
            trigger: 'blur',
          },
        ],
        limitUser: [
          {
            required: true,
            message: '每人限领数量不能为空',
            trigger: 'blur',
          },
        ],
        orgId: [
          {
            required: true,
            message: '所属机构不能为空',
            trigger: 'blur',
          },
        ],
      },
      goodsList: [],
      brandList: [],
      orgList: [],
      categoryList: [],
      options: [],
      periodList: [],
      datePicker: this.picker(),
    };
  },
  created() {
    this.getOrgList();
  },
  methods: {
    picker() {
      return {
        // 可选时间大于等于当前时间
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      };
    },
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/coupon/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.coupon, keys(this.dataForm));
              // this.dataForm = { ...this.dataForm, ...data.coupon };
              if (data.coupon.limitType === 2) {
                this.dataForm.goodsIds = data.coupon.goodsAndPackage.map(
                  (item) => {
                    return [item.categoryId, item.goodsAndPackage];
                  },
                );
              }
              this.checkOrg();
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.limitType === 2) {
        this.dataForm.goodsIds.forEach((item) => {
          this.dataForm.goodsAndPackage.push({
            categoryId: item[0],
            goodsAndPackage: item[1],
          });
        });
      }

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/coupon/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    checkOrg() {
      if (this.dataForm.periodType === 1) {
        this.getPeriodList();
      }
      if (this.dataForm.limitType === 0) {
        return;
      }
      if (this.dataForm.orgId === '') {
        this.$message({
          message: '请先选择供应商',
          type: 'error',
        });
      }
      if (this.dataForm.limitType === 1) {
        this.getCategoryList();
      } else {
        this.getGoodsAndPackage();
      }
    },
    getCategoryList() {
      getCategoryList(this.dataForm.orgId).then(({ data }) => {
        if (data && data.status === 0) {
          this.categoryList = data.data.items;
        }
      });
    },
    getGoodsAndPackage() {
      getGoodsAndPackage(this.dataForm.orgId).then(({ data }) => {
        if (data && data.code === 0) {
          this.options = data.option.options;
        }
      });
    },
    getPeriodList() {
      if (this.dataForm.orgId) {
        this.$http({
          url: `/tc/orgperiod/combo/${this.dataForm.orgId}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
          }
        });
      }
    },
  },
};
</script>
